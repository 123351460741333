var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbItems}}),(_vm.event && _vm.race)?_c('v-card',[_c('Header',{attrs:{"event":_vm.event}}),_c('v-card-title',{staticClass:"headline"},[_vm._v("Relay slots for "+_vm._s(_vm.race.name))]),_c('v-card-text',[_vm._v(" Customize the relay slots for this race. "),_c('v-alert',{staticClass:"mt-8",attrs:{"type":"info"}},[_vm._v("This is a BETA feature. Feedback is welcome through our contact form :)")])],1),_c('v-card-text',[_c('strong',[_vm._v("Note:")]),_vm._v(" do not make changes to the slot configuration after participants have been invited! ")]),(_vm.race.team == 'RELAY_TIME_SLOTS')?_c('v-card-text',[_c('strong',[_vm._v("Note:")]),_vm._v(" all times are in the "),_c('strong',[_vm._v(_vm._s(_vm.event.timezone))]),_vm._v(" time zone. Participants will see the times in their local time zone during slot assignment. ")]):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.slots,"items-per-page":100,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"mx-4",attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',{staticClass:"ml-0 subtitle"},[_vm._v("Relay Slots")])],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"hide-details":""},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticStyle:{"width":"100px"},attrs:{"items":_vm.$helpers.prefixSelectListWith(_vm.siteData.activities, {type: null, text:'Any sport'}),"item-value":"type","item-text":"text","placeholder":"- Any -","hide-details":""},model:{value:(item.type),callback:function ($$v) {_vm.$set(item, "type", $$v)},expression:"item.type"}})]}},{key:"item.min_distance",fn:function(ref){
var item = ref.item;
return [_c('DistanceTextArea',{attrs:{"unit":_vm.event.unit,"hideDetails":""},model:{value:(item.min_distance),callback:function ($$v) {_vm.$set(item, "min_distance", $$v)},expression:"item.min_distance"}})]}},{key:"item.distance",fn:function(ref){
var item = ref.item;
return [_c('DistanceTextArea',{attrs:{"unit":_vm.event.unit,"hideDetails":""},model:{value:(item.distance),callback:function ($$v) {_vm.$set(item, "distance", $$v)},expression:"item.distance"}})]}},{key:"item.max_distance",fn:function(ref){
var item = ref.item;
return [_c('DistanceTextArea',{attrs:{"unit":_vm.event.unit,"hideDetails":""},model:{value:(item.max_distance),callback:function ($$v) {_vm.$set(item, "max_distance", $$v)},expression:"item.max_distance"}})]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [_c('DateAndTimeWithTimeZonePicker',{attrs:{"timeZone":_vm.event.timezone,"hideDetails":"","label":null,"timeLabel":null,"forceShowTime":""},model:{value:(item.start),callback:function ($$v) {_vm.$set(item, "start", $$v)},expression:"item.start"}})]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [_c('DateAndTimeWithTimeZonePicker',{attrs:{"timeZone":_vm.event.timezone,"hideDetails":"","label":null,"timeLabel":null,"forceShowTime":""},model:{value:(item.end),callback:function ($$v) {_vm.$set(item, "end", $$v)},expression:"item.end"}})]}},{key:"footer",fn:function(){return [_c('v-btn',{attrs:{"text":""},on:{"click":_vm.addSlot}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"gray"}},[_vm._v("fa-plus-circle")]),_vm._v(" Add a slot")],1),_c('span',{staticClass:"text-muted"},[_vm._v("(To delete a slot: clear the display name and press Save)")])]},proxy:true}],null,false,3776257162)}),_c('v-card-text',[_c('v-btn',{attrs:{"color":"primary","large":""},on:{"click":_vm.save}},[_vm._v("Save Slots")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.reset}},[_vm._v("Delete all Slots")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }